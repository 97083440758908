import React from 'react';
import { AiOutlineHome } from "react-icons/ai";
import { BiCodeAlt } from "react-icons/bi";
import { GiSkills } from "react-icons/gi";
import { BiBriefcaseAlt2 } from "react-icons/bi";
import { RiContactsLine } from "react-icons/ri";

export default function Navbar() {
  return (
    <nav className='navbar' id='navbar'>
        <ul className="navbar-links-wrapper">
            <li>
                <a href="#home" className="navbar-link">
                    <span className="navLink-content">
                        <AiOutlineHome />
                        <span className='navLink-text'>Home</span>
                    </span>
                </a>
            </li>
            <li>
                <a href="#skills" className="navbar-link">
                    <span className="navLink-content">
                        <BiCodeAlt />
                        <span className='navLink-text'>Skills</span>
                    </span>
                </a>
            </li>
            <li>
                <a href="#about" className="navbar-link">
                    <span className="navLink-content">
                        <GiSkills />
                        <span className='navLink-text'>About</span>
                    </span>
                </a>
            </li>
            <li>
                <a href="#portfolio" className="navbar-link">
                    <span className="navLink-content">
                        <BiBriefcaseAlt2 />
                        <span className='navLink-text'>Portfolio</span>
                    </span>
                </a>
            </li>
            <li>
                <a href="#contact" className="navbar-link">
                    <span className="navLink-content">
                        <RiContactsLine />
                        <span className='navLink-text'>Contact</span>
                    </span>
                </a>
            </li>
        </ul>
    </nav>
  )
}
