import React from 'react';
import { HiOutlineSun, HiOutlineMoon } from "react-icons/hi";
import { toggleDarkMode } from '../store/functions';
import Navbar from './Navbar';

export default function Header() {
  return (
    <header className='header'>
        <div className="container">
            <a href="#home" className="logo-name">Diuere</a>
            <HiOutlineSun id="lightModeIcon" onClick={toggleDarkMode}/>
            <HiOutlineMoon id='darkModeIcon' onClick={toggleDarkMode}/>
            <Navbar />
        </div>
    </header>
  )
}
