import { toggleClass } from "./functions";

/*========== main observer function ===========*/

const getObserver = (element, activate, deactivate, threshold) => {
    /* 
        receives a DOM element; 
        creates an IntersectionObserver instance; 
        listen to intersecting changes; 
        if the intersecting is true, it runs a function passed as argument; 
        if intersecting is false, it runs a different function passed as argument.
    */

    const elementObserver = new IntersectionObserver((entries, elementObserver) => {
        entries.forEach((entry) => {
            if(entry.isIntersecting){
                if(activate) activate();
            } else if(!entry.isIntersecting){
                if(deactivate) deactivate();
            }
        })
    }, {
        threshold: threshold,
    })
    elementObserver.observe(element);
    return;
}

/*========== observer handlers (no export) ===========*/

const sectionObserver = (sections, links, threshold) => {
    const activateLink = (id) => {
        // should loop through all navbar links
        links.forEach(link => {
            // find a way to identify each link so it can be compered to each section id
            const { href } = link;
            const hrefArr = href.split("/");

            if(hrefArr[hrefArr.length - 1] === `#${id}`){ // if they match, the .navbar-link-active class should be applied
                toggleClass(link, true, false, "navbar-link-active"); // imported function
            } else { // if they don't match, the .navbar-link-active class should be removed
                toggleClass(link, false, true, "navbar-link-active"); // imported function
            }
        })
    }

    sections.forEach(section => {
        const { id } = section;
        getObserver(section, () => activateLink(id), null, threshold);
    })
}

const handleSectionObserver = (deviceHeight) => { // handles the .navbar-link class activation state once it hits the proper section element
    const sections = document.querySelectorAll(".section");
    const navbarLinks = document.querySelectorAll(".navbar-link");

    // depending on the height of the device, the link style activation point will change, therefore the section observers need to have different threshold.
    if(deviceHeight < 600){
        sectionObserver(sections, navbarLinks, .3);
    } else if (deviceHeight < 1200){
        sectionObserver(sections, navbarLinks, .5);
    } else {
        sectionObserver(sections, navbarLinks, .9);
    }
}

/*========== observers handlers (export) ===========*/

export const heightObserver = () => { // handles the body resize event
    const resizeObserver = new ResizeObserver(entries => 
        // on resize, the handleSectionObserver function should be called
        handleSectionObserver(window.innerHeight)
      )
      
      resizeObserver.observe(document.body);
}

// the following functions handle the transform effect of certain elements once they are intersecting

export const topBottomObserver = () => {
    const sectionTitle = document.querySelectorAll(".section-title");
    const sectionSubtitle = document.querySelectorAll(".section-subtitle");
    const smContactIcons = document.querySelectorAll(".sm-contact-icon");
    const skillsFloatWrapper = document.querySelectorAll(".skills-float-wrapper");
    const skill = document.querySelectorAll(".skill");
    const scrollDownWrapper = document.querySelector(".scroll-down-wrapper").childNodes;
    
    // applying class to the given elements
    sectionTitle.forEach(title => getObserver(title, () => toggleClass(title, true, false, "render-bottom-top"), () => toggleClass(title, false, true, "render-bottom-top"), .0001));
    sectionSubtitle.forEach(subtitle => getObserver(subtitle, () => toggleClass(subtitle, true, false, "render-bottom-top"), () => toggleClass(subtitle, false, true, "render-bottom-top"), .0001));
    smContactIcons.forEach(icon => getObserver(icon, () => toggleClass(icon, true, false, "render-bottom-top"), () => toggleClass(icon, false, true, "render-bottom-top"), .0001));
    skillsFloatWrapper.forEach(wrapper => getObserver(wrapper, () => toggleClass(wrapper, true, false, "render-bottom-top"), () => toggleClass(wrapper, false, true, "render-bottom-top"), .0001));
    skill.forEach(wrapper => getObserver(wrapper, () => toggleClass(wrapper, true, false, "render-bottom-top"), () => toggleClass(wrapper, false, true, "render-bottom-top"), .0001));
    scrollDownWrapper.forEach(child => getObserver(child, () => toggleClass(child, true, false, "render-right-left"), () => toggleClass(child, false, true, "render-right-left"), .0001));
};

export const bottomTopObserver = () => {
    const heroText = document.querySelector(".hero-text").childNodes;
    const heroImgWrapper = document.querySelector(".hero-img-wrapper").childNodes;

    heroText.forEach(child => getObserver(child, () => toggleClass(child, true, false, "render-top-bottom"), () => toggleClass(child, false, true, "render-top-bottom"), .0001));
    heroImgWrapper.forEach(child => getObserver(child, () => toggleClass(child, true, false, "render-top-bottom"), () => toggleClass(child, false, true, "render-top-bottom"), .0001));
}

export const leftRightObserver = () => {
    const projectImg = document.querySelectorAll(".project-img");
    const dmsWrapper = document.querySelector(".dms-wrapper").childNodes;
    
    projectImg.forEach(img => getObserver(img, () => toggleClass(img, true, false, "render-left-right"), () => toggleClass(img, false, true, "render-left-right"), .5));
    dmsWrapper.forEach(child => getObserver(child, () => toggleClass(child, true, false, "render-left-right"), () => toggleClass(child, false, true, "render-left-right"), .5));
}

export const rightLeftObserver = () => {
    const projectDescriptionWrapper = document.querySelectorAll(".project-description-wrapper");
    const emailForm = document.querySelector(".email-form").childNodes;
    
    projectDescriptionWrapper.forEach(wrapper => getObserver(wrapper, () => toggleClass(wrapper, true, false, "render-right-left"), () => toggleClass(wrapper, false, true, "render-right-left"), .5));
    emailForm.forEach(child => getObserver(child, () => toggleClass(child, true, false, "render-right-left"), () => toggleClass(child, false, true, "render-right-left"), .5));
}

export const popUpObserver = () => {
    const services = document.querySelectorAll(".service");
    const swiperBtnPrev = document.querySelectorAll(".swiper-button-prev");
    const swiperBtnNext = document.querySelectorAll(".swiper-button-next");

    services.forEach(service =>  getObserver(service, () => toggleClass(service, true, false, "render-pop-up"), () => toggleClass(service, false, true, "render-pop-up"), .5))
    swiperBtnPrev.forEach(swiperBtn =>  getObserver(swiperBtn, () => toggleClass(swiperBtn, true, false, "render-pop-up"), () => toggleClass(swiperBtn, false, true, "render-pop-up"), .5));
    swiperBtnNext.forEach(swiperBtn =>  getObserver(swiperBtn, () => toggleClass(swiperBtn, true, false, "render-pop-up"), () => toggleClass(swiperBtn, false, true, "render-pop-up"), .5));
}