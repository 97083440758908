import React from 'react';
import { MdKeyboardArrowRight } from "react-icons/md";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { portfolioData } from '../store/data';
import { parseData } from '../store/functions';

export default function Projects() {

    const portfolio = () => {
        const portfolios = parseData(portfolioData);

        return (
            portfolios.map((portfolio, id) => (
                <SwiperSlide key={id} className="project-content-wrapper">
                    <img src={portfolio.value.imgUrl} className="project-img" alt="" />
                    <div className="project-description-wrapper">
                        <h3 className="project-title">{portfolio.value.title}</h3>
                        <p className="project-description">{portfolio.value.description}</p>
                        <a href={portfolio.value.projectUrl} className="project-link portfolio-btn main-btn-style" target="_blank" rel="noreferrer">
                            <span>Github</span> <MdKeyboardArrowRight />
                        </a>
                    </div>
                </SwiperSlide>
            ))
        )
    }

  return (
    <section className="portfolio section" id='portfolio'>
        <div className="container">
            <h2 className="portfolio-title section-title">Portfolio</h2>
            <span className="portfolio-subtitle section-subtitle">Most recent work</span>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="projects-wrapper"
                >
                {portfolio()}
            </Swiper>
        </div>
    </section>
  )
}