import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { BsArrowRightShort, BsSignal } from "react-icons/bs";
import { FaWhatsapp, FaTelegram } from "react-icons/fa";

export default function Contact() {
    const form = useRef();
    const [formController, setFormController] = useState(
        {
            from_name: "",
            from_email: "",
            message: ""
        }
    )
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormController(prevForm => (
            {
                ...prevForm,
                [name]: value,
            }
        ))
    }
    
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_e8tjba3', 'template_bhovy0m', form.current, 'J-tFMwV5sJF45Q8em')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

      e.target.reset();
      setFormController( { from_name: "", from_email: "", message: "" } );
    }

  return (
    <section className="contact section" id='contact'>
        <div className="container">
            <h2 className="contact-title section-title">Contact</h2>
            <span className="contact-subtitle section-subtitle">Contact me</span>
            <div className="contact-wrapper">
                <div className="dms-wrapper">
                    <h3>Talk to me</h3>
                    <div className="dm-wrapper">
                        <FaWhatsapp />
                        <h4 className="dm-title">Whatsapp</h4>
                        <span className="dm-location">+5573991119047</span>
                        <a href="https://api.whatsapp.com/send?phone=5573991119047&text=Hi there. Feel free to text me at any moment!" target="_blank" rel="noopener" className="dm-link main-btn-style">
                            <span>Write me</span> <BsArrowRightShort />
                        </a>
                    </div>
                    <div className="dm-wrapper">
                        <FaTelegram />
                        <h4 className="dm-title">Telegram</h4>
                        <span className="dm-location">+5573991119047</span>
                        <a href="https://t.me/Diuere_Souza" target="_blank" rel="noopener" className="dm-link main-btn-style">
                            <span>Write me</span> <BsArrowRightShort />
                        </a>
                    </div>
                    <div className="dm-wrapper">
                        <BsSignal />
                        <h4 className="dm-title">Signal</h4>
                        <span className="dm-location">+5573991119047</span>
                        <a href="https://signal.me/#p/+5573991119047" target="_blank" rel="noopener" className="dm-link main-btn-style">
                            <span>Write me</span> <BsArrowRightShort />
                        </a>
                    </div>
                </div>
                <form className="email-form" ref={form} onSubmit={(e) => sendEmail(e)}>
                    <h3>Write me your project</h3>
                    
                    <div className="form-template">
                        <input type="text" className={formController.from_name === "" ? "" : "template-selected"} name="from_name" id="userName" autoComplete='off' required onChange={(e) => handleChange(e)}/>
                        <label htmlFor="userName">Name</label>
                    </div>
                    <div className="form-template">
                        <input type="email" className={formController.from_email === "" ? "" : "template-selected"} name="from_email"  id="userEmail" autoComplete="off" required onChange={(e) => handleChange(e)}/>
                        <label htmlFor="userEmail">Email</label>
                    </div>
                    <div className="form-template">
                        <textarea className={formController.message === ""? "" : "template-selected"} name="message" id="contactMessage" cols="30" rows="10" autoComplete="off" required onChange={(e) => handleChange(e)} />
                        <label htmlFor="contactMessage" className="textarea">Message</label>
                    </div>
              
                    <button className="contact-btn main-btn-style">
                        <span>submit</span>
                    </button>
                </form>
        
            </div>
        </div>
    </section>
  )
}
