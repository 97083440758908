import React from 'react';
import SmContact from './SmContact';
import { BsMouse } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";
import BlobImg from './BlobImg';

export default function Hero() {
  return (
    <section className="hero section" id="home">
        <div className="container">
            <SmContact />
            <div className="hero-center">
                <div className="hero-text">
                    <h1 className="hero-title">Hi, I'm Diuere</h1>
                    <span className="hero-subtitle">Frontend</span>
                    <p>Develop web applications, specifically their design implementations and interactions.</p>
                    <a href="#contact" className="contact-btn main-btn-style">
                        <span>Contact Me</span> <MdKeyboardArrowRight />
                    </a>
                </div>
                <div className="hero-img-wrapper">
                    <BlobImg />
                </div>
            </div>
            <div className="scroll-down-wrapper">
                <BsMouse />
                <span>scroll down</span>
            </div>
        </div>
    </section>
  )
}
