import React from 'react';
import SmContact from "./SmContact";

export default function Footer() {
  return (
    <footer className="footer">
        <div className="container">
          <div className="footer-content-wrapper">
            <h2 className="section-title">Diuere</h2>
            <p>Frontend - Web developer</p>
            <SmContact />
          </div>
          <div className="copyright-wrapper">
            <p>Copyright &copy; 2022 Diuere Souza. All rights reserved.</p>
          </div>
        </div>
    </footer>
  )
}
