import React, { useState } from 'react';
import { characteristic } from '../store/data';
import { parseData } from '../store/functions';
import { MdVerified} from "react-icons/md";


export default function About() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const currentData = characteristic[currentIndex];
    const skills = parseData(currentData.skills);

    const skillsSelector = characteristic.map((skillData, id) => {
        const applyClass = currentIndex === id ? "services-selected" : "";

        return (
            <button key={id} className={`services-selector main-btn-style ${applyClass}`} onClick={() => setCurrentIndex(id)}>
                <span>{skillData.category}</span>
            </button>
        )
    });

    const skillsElem = skills.map((skill, id) => {
        return (
            <div key={id} className="service render-pop-up">
                <span className="verified-icon"><MdVerified /></span>
                <span className="service-value">
                    <span>{skill.name}</span>
                    <span>{skill.value ? `: ${skill.value}` : ""}</span>
                </span>
            </div>
        )
    });
    
  return (
    <section className="about section" id="about">
        <div className="container">
            <h2 className="about-title section-title">About</h2>
            <div className="about-body">
                <div className="about-container">
                    <span className="about-subtitle section-subtitle">About me</span>
                    <p className="about-description">
                        My name is Diuere Souza, I was born and raised in a small northeast town in Brazil. Currently, I am 19 (nineteen) years old, and I am a junior front-end web developer.
                    </p>
                </div>
                <div className="services-container">
                    <span className="services-subtitle section-subtitle">What I offer</span>
                    <div className="services-selector-wrapper">
                        {skillsSelector}
                    </div>
                    <div className="services-wrapper">
                        {skillsElem}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}