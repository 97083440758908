export const toggleClass = (element, add, remove, classN)  => { // will mainly be used on the observers handles
    if(add) return element.classList.add(classN);
    if(remove) return element.classList.remove(classN);
}

export const handleHeightChange = () => {
    const deviceHeight = window.innerHeight;
    return deviceHeight;
}

export const parseData = (skills) => {
    const data = []

    for(let skill of Object.keys(skills)){
        const newSkill = {
            name: skill,
            value: skills[skill]
        }

        data.push(newSkill);
    }

    return data;
}

export const heroSubtitleLoop = () => {
    const heroSubtitle = document.querySelector('.hero-subtitle');
    const fixedText = "frontend";
    const loopedWord = "developer".split("");
    let wordArray = [];
    let counter = 0;

    setInterval(() => {
        if(counter === loopedWord.length){
            counter = 0;
            wordArray = [];
        } else {
            wordArray.push(loopedWord[counter]);
            counter++;
            heroSubtitle.textContent = `${fixedText} ${wordArray.join("")}`;
        }
    }, 500);
}

export const toggleDarkMode = () => {
    const body = document.querySelector("body");

    body.classList.toggle("dark-mode");
}

export const showSkillPercentage = () => {
    const skills = document.querySelectorAll(".skill");
    let counter = 0;

    skills.forEach(skill => {
        counter++
        const skillClass = skill.classList;
        const value = skill.lastChild.innerHTML;

        if(skillClass[1] === `skill_${counter}`){
            skill.style.setProperty("--skill-after-width", `${value}`)
        }
    })
}