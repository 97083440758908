import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import { bottomTopObserver, leftRightObserver, popUpObserver, rightLeftObserver, topBottomObserver, heightObserver } from './store/observers';
import { heroSubtitleLoop, showSkillPercentage } from './store/functions';

function App() {
  useEffect(() => {
    heightObserver();
    topBottomObserver();
    bottomTopObserver();
    leftRightObserver();
    rightLeftObserver();
    popUpObserver();

    showSkillPercentage();
    heroSubtitleLoop();
  }, [])

  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
