export const skillsData = [
    {
        category: "development",
        skills: {
            "Frontend": "85%",
            "Backend": "10%"
        }
    },
    {
        category: "technologies",
        skills: {
            "JavaScript": "70%",
            "ReactJS": "80%",
            "TypeScript": "75%",
            "SCSS": "80%",
            "Tailwind": "60%",
            "NodeJs": "5%",
        }
    },
    {
        category: "tools",
        skills: {
            "Figma": "40%",
            "GitHub": "70%"
        }
    }
]
export const characteristic = [
        {
            category: "services",
            skills: {
            "Web development": "Any interactions and responsive environment",
            "Remote Services": "they are my preference.",
            "Flexible TIme": "available to work at any time zone.",
            "Organized projects": "precise, organized and clean code are top priority.",
        }
    },
    {
        category: "strengths",
        skills: {
            "Venturesome": "I am not afraid to go beyond my current limitations.",
            "Motived": "I love what I do, I'm proud of what I already know, and I'm eager to learn more.",
            "Bilingual": "I can speak English and Portuguese fluently.",
            "Persistent": "I believe I will only truly fail if I give up.",
            "Polite": "I admire politeness, thus I always want to be as much polite as possible.",
            "Well disciplined": "I'm always discipling myself, being a self-taught in almost everything by observing."
        }
    },
    {
        category: "weaknesses",
        skills: {
            "Lack of creativity": "I'm not creative on my own. I'm better at gathering ideas and improving them.",
            "Indecisive": "I tend to be perfectionist, therefore requiring a considerable amount of time and thinking to make decisions.",
            "Introvert": "Though I can be extrovert when needed, I tend to do tasks alone and avoid small chats."
        }
    }
]

export const portfolioData = [
    {
        imgUrl: "/cocktail-img.jpg",
        title: "Commercial website",
        description: "Explore an instance of a commercial website.",
        projectUrl: "https://github.com/diuere/CocktailExplorer.github.io"
    },
    {
        imgUrl: "/having-fun.jpeg",
        title: "Play-ground App",
        description: "Explore some small known Games and Applications.",
        projectUrl: "https://github.com/diuere/playground.github.io"
    }
]