import React from 'react';
import Hero from './Hero';
import Projects from './Projects';
import About from './About';
import Skills from './Skills';
import Contact from './Contact';

export default function Main() {
  return (
    <main>
        <Hero />
        <Skills />
        <About />
        <Projects />
        <Contact />
    </main>
  )
}
