import React, { useEffect } from 'react';
import { VscJson, VscChevronDown } from "react-icons/vsc";
import { TbTools } from "react-icons/tb";
import { RiCodeView } from "react-icons/ri";
import { skillsData } from '../store/data';
import { parseData } from '../store/functions';

let counter = 0;

export default function Skills() {

    const skills = (skillData) => {
        const allSkills = parseData(skillData.skills);

        return (
            allSkills.map((skill, id) => {
                counter++;

                return (
                    <div key={id} className={`skill skill_${counter}`}>
                        <span className="skill-name">{skill.name}</span>
                        <span className="skill-value">{skill.value}</span>
                    </div>
                )
            })
        ) 
    } 

    useEffect(() => {
        // handles the skills percentage implement
    }, [counter])
    
  return (
    <section className="skills section" id='skills'>
        <div className="container">
            <div className="skills-float-wrapper render-bottom-top">
                <h2 className="section-title">Skills</h2>
                <div className="skills-body">
                    <div className="skills-wrapper skill-selected">
                        <button className="skill-selector">
                            <span className="skill-icon"><VscJson /></span>
                            <span className="skills-selector-text" >{skillsData[0].category}</span>
                            <span className="skill-arrowDown"><VscChevronDown /></span>
                        </button>
                        <div className="skill-wrapper">
                            {skills(skillsData[0])}
                        </div>
                    </div>
                    <div className="skills-wrapper">
                        <button className="skill-selector">
                            <span className="skill-icon"><RiCodeView /></span>
                            <span className="skills-selector-text">{skillsData[1].category}</span>
                            <span className="skill-arrowDown"><VscChevronDown /></span>
                        </button>
                        <div className="skill-wrapper">
                            {skills(skillsData[1])}
                        </div>
                    </div>
                    <div className="skills-wrapper">
                        <button className="skill-selector">
                            <span className="skill-icon"><TbTools /></span>
                            <span className="skills-selector-text">{skillsData[2].category}</span>
                            <span className="skill-arrowDown"><VscChevronDown /></span>
                        </button>
                        <div className="skill-wrapper">
                            {skills(skillsData[2])}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
